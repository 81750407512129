import { createRoot } from 'react-dom/client';
import { logTinyEvent } from 'TinyEventLogger';
import { FrontRoyalRoot } from './FrontRoyalRoot';

export function bootstrapFrontRoyalApp() {
    window.addEventListener('DOMContentLoaded', () => {
        // This element is put on the screen in index.html.erb
        const mainAppNode = document.getElementById('app-root');
        if (!mainAppNode) {
            logTinyEvent('app_root_not_found', {});
            throw new Error('The app-root element was not found');
        }

        const root = createRoot(mainAppNode);
        root.render(<FrontRoyalRoot />);
    });

    // In the past we weren't waiting for DOMContentLoaded to createRoot.
    // When investigating https://trello.com/c/qLjrqdSp we had a hunch that
    // maybe it was possible to hit this code before the DOM was ready.
    // It doesn't matter anymore, but now we're curious.
    // DELETEME: after we satisate our curiosity
    const mainAppNode = document.getElementById('app-root');
    if (!mainAppNode) {
        logTinyEvent('app_root_not_found_before', {});
    }
}
